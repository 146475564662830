hp(window).on('load', () => {
    require('../partials/gpt-help-modal');

    let toTopBtn = hp('#to-top-btn');

    if (!toTopBtn.isEmpty()) {
        toTopBtn.on('click', (e) => {
            window.scrollTo({ top: 0 })
        })
    }
})
