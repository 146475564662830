const GPT_FORM = hp('#gpt-help-form');

let regeneratingCounter = 1;

let fetchSetup = (url = '', data = {}, beforeSend = () => { }, error = () => { }, success = () => { }, complete = () => { }) => {
    beforeSend(data)

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': hp('meta[name="csrf-token"]').attr('content')
        },
    })
        .then(response => {
            return {
                ok: response.ok,
                response: response.json()
            };
        })
        .then(result => {
            if (result.ok) {
                try {
                    result.response.then(res => {
                        success(res);
                        complete(result);
                    })
                } catch (error) {
                    success(result);
                    complete(result);
                }
            } else {
                error(result);
                complete(result);
            }


        }).catch(errorData => {
            error(errorData)
            complete(errorData);
        })
}

GPT_FORM.on('submit', (e) => {
    e.preventDefault();

    let getPercent = (num) => (num * 100) / 5;

    let refreshProgressBar = () => {
        PROGRESS_BAR.css('width', getPercent(regeneratingCounter) + '%');
    };

    let refreshCaptcha = () => {
        refreshReCaptchaV3('gpt_modal_action_id', 'gpt_modal_action');
    }

    const url = GPT_FORM.attr('action');

    let data = {
        'prompt': 'domain-help',
        'company-name': '',
        'key-words': '',
        'company-activity': '',
        'domain-type': 'beauty',
        'g-recaptcha-response': getReCaptchaV3Response('gpt_modal_action_id')
    };

    const SUBMIT_BTN = GPT_FORM.find('button[type="submit"]')

    const INPUT_COMPANY_NAME = GPT_FORM.find('#company-name');
    const INPUT_KEY_WORDS = GPT_FORM.find('#key-words');
    const INPUT_COMPANY_ACTIVITY = GPT_FORM.find('#company-activity');
    const INPUT_DOMAIN_TYPE_BEAUTY = GPT_FORM.find('#beauty');
    const INPUT_DOMAIN_TYPE_SHORT = GPT_FORM.find('#short');

    const PROGRESS_BAR = hp('.explanation-block .progress-bar');

    data['company-name'] = INPUT_COMPANY_NAME.val()

    if (INPUT_KEY_WORDS.val() != '') {
        data['key-words'] = INPUT_KEY_WORDS.val()
    }

    data['company-activity'] = INPUT_COMPANY_ACTIVITY.val()

    if (INPUT_DOMAIN_TYPE_SHORT.is(':checked')) {
        data['domain-type'] = INPUT_DOMAIN_TYPE_SHORT.val()
    }

    if (INPUT_DOMAIN_TYPE_BEAUTY.is(':checked')) {
        data['domain-type'] = INPUT_DOMAIN_TYPE_BEAUTY.val()
    }

    if (regeneratingCounter < 5) {
        fetchSetup(url, data,
            data => { // before send
                SUBMIT_BTN.addClass('disabled')
            },

            errorData => { // error
                hp('.gpt-answer-block').removeClass('active')

                const ERROR_BLOCK = hp('.gpt-error-block');

                const MSG_BLOCK = ERROR_BLOCK.find('.alert')

                if (errorData.msg) {
                    MSG_BLOCK.text(errorData.msg);
                }

                ERROR_BLOCK.removeClass('d-none')
            },

            result => { // success
                if ('ok' in result && !result.ok) {
                    hp('.gpt-answer-block').removeClass('active')

                    const ERROR_BLOCK = hp('.gpt-error-block');

                    const MSG_BLOCK = ERROR_BLOCK.find('.alert')

                    if (result.msg) {
                        MSG_BLOCK.text(result.msg);
                    }

                    ERROR_BLOCK.removeClass('d-none')

                    return;
                }

                try {
                    let domainsList = result.res;

                    const ANSWER_BLOCK = hp('.gpt-answer-block');
                    const DOMAINS_OUTPUT = ANSWER_BLOCK.find('.domains-output');

                    const ERROR_BLOCK = hp('.gpt-error-block');
                    ERROR_BLOCK.addClass('d-none')

                    ANSWER_BLOCK.addClass('active');

                    let domainsOutputHTML = '';

                    Array.from(domainsList).forEach(domainName => {
                        domainsOutputHTML += `<div class="domain-to-check mb-2"><a href="/domains?d=${domainName}#check-domain-main" target="blank" class="domain-name">${domainName}</a><p class="domain-zone mb-0">.com</p></div>`
                    });

                    DOMAINS_OUTPUT.html(domainsOutputHTML)
                } catch (error) {
                    console.log(error);
                    hp('.gpt-answer-block').removeClass('active')

                    const ERROR_BLOCK = hp('.gpt-error-block');

                    const MSG_BLOCK = ERROR_BLOCK.find('.alert')

                    if (result.msg) {
                        MSG_BLOCK.text(result.msg);
                    }

                    ERROR_BLOCK.removeClass('d-none')
                }
            },

            res => {// complete
                SUBMIT_BTN.removeClass('disabled')

                regeneratingCounter++;

                if (regeneratingCounter >= 5) {
                    SUBMIT_BTN.addClass('disabled')
                }

                refreshProgressBar()
                refreshCaptcha()
            }
        )
    }
})
